import React from 'react'
import './headerNav.css'

function HeaderNav({ navActive, stateChanger }) {

  return (
    <div className='headerNav w3-bar'>
        <ul>
            <li style={ navActive === 'all' ? {backgroundColor: '#2b2b2b'} : null } onClick={()=> stateChanger('all') }>All</li>
            <li style={ navActive === 'effect' ? {backgroundColor: '#2b2b2b'} : null } onClick={()=> stateChanger('effect') }>Effects</li>
            <li style={ navActive === 'synth' ? {backgroundColor: '#2b2b2b'} : null } onClick={()=> stateChanger('synth') }>Synths</li>
            <li style={ navActive === 'tool' ? {backgroundColor: '#2b2b2b'} : null } onClick={()=> stateChanger('tool') }>Tools</li>
        </ul>
    </div>
    
  )

    
}

export default HeaderNav