// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerNav {
    margin: auto;
    width: 700px;
    -webkit-user-select: none;
            user-select: none;
    position: relative;
    bottom: 10px;
    right: 90px;
}

ul {
    margin: auto;
    width: 70%;
    list-style-type: none;
    overflow: hidden;
}

li {
    margin-top: 30px;
    float: left;
    min-width: 80px;
    text-align: center;
}

li {
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
    cursor: pointer;
}

li:hover {
    background-color: #383838 !important;
}

@media (max-width: 960px) {
    .headerNav {
        width: 100%;
        position: absolute;
        top: 110px;
        left: 50px;
        height: 100px;
    }
    ul {
        width: 100%;
        position: relative;
        top: 21px;
    }
    li {
        margin: auto;
    }
}

@media (max-width : 500px) {
    ul {
        width: 100%;
        position: relative;
        top: 31px;
    }
    .headerNav {
        width: 100%;
        position: absolute;
        top: 90px;
        left: 0px;
        height: 100px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/headerNav.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,yBAAiB;YAAjB,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI;QACI,WAAW;QACX,kBAAkB;QAClB,UAAU;QACV,UAAU;QACV,aAAa;IACjB;IACA;QACI,WAAW;QACX,kBAAkB;QAClB,SAAS;IACb;IACA;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,WAAW;QACX,kBAAkB;QAClB,SAAS;IACb;IACA;QACI,WAAW;QACX,kBAAkB;QAClB,SAAS;QACT,SAAS;QACT,aAAa;IACjB;AACJ","sourcesContent":[".headerNav {\n    margin: auto;\n    width: 700px;\n    user-select: none;\n    position: relative;\n    bottom: 10px;\n    right: 90px;\n}\n\nul {\n    margin: auto;\n    width: 70%;\n    list-style-type: none;\n    overflow: hidden;\n}\n\nli {\n    margin-top: 30px;\n    float: left;\n    min-width: 80px;\n    text-align: center;\n}\n\nli {\n    display: block;\n    color: white;\n    text-align: center;\n    padding: 16px;\n    text-decoration: none;\n    cursor: pointer;\n}\n\nli:hover {\n    background-color: #383838 !important;\n}\n\n@media (max-width: 960px) {\n    .headerNav {\n        width: 100%;\n        position: absolute;\n        top: 110px;\n        left: 50px;\n        height: 100px;\n    }\n    ul {\n        width: 100%;\n        position: relative;\n        top: 21px;\n    }\n    li {\n        margin: auto;\n    }\n}\n\n@media (max-width : 500px) {\n    ul {\n        width: 100%;\n        position: relative;\n        top: 31px;\n    }\n    .headerNav {\n        width: 100%;\n        position: absolute;\n        top: 90px;\n        left: 0px;\n        height: 100px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
