// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header, .headerWithoutNav {
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #383838;
    -webkit-user-select: none;
            user-select: none;
    position: sticky;
    top: 0;
    overflow: hidden;
    z-index: 100;
    background-color: #212121;
}

.logoLink {
    width: 250px;
    float: left;
    height: 100%;
}

.logo {
    float: left;
    width: 80px;
}

.titleAndLogo {
    width: 300px;
}

.title {
    display: flex;
    align-items: center;
    height: 100%;
    width: 135px;
    color: white;
    padding-top: 15px;
}

a:link {
    text-decoration: none;
}

.dubbyDevice {
    float: right;
    margin-right: 20px;
    margin-top: 26px;
    position: absolute;
    right: 0px;
    top: 0px;
}

@media (max-width: 960px) {
    .header {
        height: 160px;
    }
    .headerWithoutNav {
        height: 100px;
    }

    .dubbyDevice {
        top: 6px;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/components/header.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,gCAAgC;IAChC,yBAAiB;YAAjB,iBAAiB;IACjB,gBAAgB;IAChB,MAAM;IACN,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;IACV,QAAQ;AACZ;;AAEA;IACI;QACI,aAAa;IACjB;IACA;QACI,aAAa;IACjB;;IAEA;QACI,QAAQ;IACZ;EACF","sourcesContent":[".header, .headerWithoutNav {\n    width: 100%;\n    height: 80px;\n    border-bottom: 1px solid #383838;\n    user-select: none;\n    position: sticky;\n    top: 0;\n    overflow: hidden;\n    z-index: 100;\n    background-color: #212121;\n}\n\n.logoLink {\n    width: 250px;\n    float: left;\n    height: 100%;\n}\n\n.logo {\n    float: left;\n    width: 80px;\n}\n\n.titleAndLogo {\n    width: 300px;\n}\n\n.title {\n    display: flex;\n    align-items: center;\n    height: 100%;\n    width: 135px;\n    color: white;\n    padding-top: 15px;\n}\n\na:link {\n    text-decoration: none;\n}\n\n.dubbyDevice {\n    float: right;\n    margin-right: 20px;\n    margin-top: 26px;\n    position: absolute;\n    right: 0px;\n    top: 0px;\n}\n\n@media (max-width: 960px) {\n    .header {\n        height: 160px;\n    }\n    .headerWithoutNav {\n        height: 100px;\n    }\n\n    .dubbyDevice {\n        top: 6px;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
