import React, { useState, useEffect } from 'react'
import './header.css'
import mainLogo from '../images/componental-logo.png';
import { Link } from "react-router-dom";
import HeaderNav from './HeaderNav';
import DubbyConnectedButton from './DubbyConnectedButton';

function Header({ navActive, stateChanger, stateChangerDevice, showHeaderNav }) {

  const [ dubbyDevice, setDubbyDevice ] = useState(null); 

  const MINUTE_MS = 200;

  useEffect(() => {

      const interval = setInterval(() => {

        if (navigator.usb) {
        const usbDevices = navigator.usb.getDevices();
          usbDevices.then(async (devices) => {
            let selDevice = devices.filter(d => d.productName === "Daisy Bootloader")[0];
            if (selDevice) {
              if (dubbyDevice == null) {
                setDubbyDevice(selDevice);
                stateChangerDevice(selDevice);
              }
            } else {
              setDubbyDevice(null);
              stateChangerDevice(null);
            }
          })

        }
      }, MINUTE_MS);
      return () => clearInterval(interval);
      

}, [dubbyDevice])


  const handleClick = async () => {


    if (navigator.usb) {
      const filters = [{ vendorId: 0x0483, productId: 0xdf11 }];
      await navigator.usb.requestDevice({ filters }).then(async (selectedDevice) => {
        setDubbyDevice(selectedDevice);
        stateChangerDevice(selectedDevice);
      }).catch((error) => {
        console.log("Error during connection: " + error);
      });
    }
  };

  return (
    <div className={showHeaderNav ? "header" : "headerWithoutNav"}>
        <Link to={'/'} className="logoLink">
            <div className='titleAndLogo'>
              <img src={mainLogo} alt='logo' className="logo" />
              <div className="title">
                <p>Dubby App Store</p>
              </div>
            </div>
          </Link>


        <div className='dubbyDevice' style={!dubbyDevice ? {cursor: "pointer"} : null} onClick={!dubbyDevice ? handleClick : null}>
            <DubbyConnectedButton connected={ dubbyDevice ? true : false} />
        </div>
        { showHeaderNav ? <HeaderNav navActive={navActive} stateChanger = {stateChanger} /> : null }
    </div>
  )
}

export default Header